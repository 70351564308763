import * as React from 'react';
import styled from 'styled-components';
import { slidingBottomToTop } from '@doltech/utils/lib/animations/slide';
import { verticalSpace } from '@doltech/utils/lib/css-style';
import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';

const Container = styled.div`
  height: 492px;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  background-color: #fefefd;
  display: flex;
  justify-content: flex-start;

  ${fromScreen(1128)} {
    justify-content: center;
  }
`;

const ContentAdvertisement = styled.div`
  width: 100% - 16px;
  height: 492px;
  display: flex;
  align-items: center;
  padding: 0 16px;

  ${fromScreen(768)} {
    padding: 0 32px;
    width: 100% - 32px;
  }

  ${fromScreen(1128)} {
    padding: 0;
    width: 1080px;
    position: relative;
  }
`;

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 983px;
  ${verticalSpace(20)};
`;

const GradientLayer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    121deg,
    #f9fafa 36.58%,
    rgba(249, 250, 250, 0.9) 52.32%,
    rgba(249, 250, 250, 0) 104.06%
  );
`;

const Image = styled.img`
  ${slidingBottomToTop};
  width: 100%;
  height: 100%;
`;

interface FreeTestPromotionBannerTemplateProps {
  renderContent: React.ReactNode;
  imageUrl?: string;
}

export const FreeTestPromotionBannerTemplate = (props: FreeTestPromotionBannerTemplateProps) => {
  const { renderContent, imageUrl } = props;

  return (
    <Container className="section-free-test-promotion">
      <ContentAdvertisement>{renderContent}</ContentAdvertisement>
      <Backdrop>
        <Image loading="lazy" alt="promotion-background" src={imageUrl} />
        <Image loading="lazy" alt="promotion-background" src={imageUrl} />
      </Backdrop>
      <GradientLayer />
    </Container>
  );
};
