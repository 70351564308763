import * as React from 'react';
import styled from 'styled-components';

import { IconCourseCheck } from '@doltech/icons/IconCourseCheck';
import { ResponsiveTypography } from '../../Typography/v3/ResponsiveTypography';

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FillWidth = styled.div`
  flex: 1;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  user-select: none;

  .select-dropdown-item {
    text-align: left;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

interface SelectDropdownItemContentProps {
  children?: React.ReactNode;
}

export const SelectDropdownItemContent = (props: SelectDropdownItemContentProps) => {
  const { children } = props;

  return <Container>{children}</Container>;
};

const DropdownText = ({ text, color = 'black100' }: { text: string; color?: string }) => (
  <FillWidth className="select-dropdown-item text">
    <ResponsiveTypography.Text variant="medium/14-20" color={color}>
      {text}
    </ResponsiveTypography.Text>
  </FillWidth>
);

const DropdownSubText = ({ text }: { text: string }) => (
  <ResponsiveTypography.Text
    className="select-dropdown-item sub-text"
    variant="medium/14-20"
    color="black60"
  >
    {text}
  </ResponsiveTypography.Text>
);

const DropdownCheck = ({ checkIcon }: { checkIcon: React.ReactNode }) => (
  <Icon className="select-dropdown-item check">
    {checkIcon || <IconCourseCheck size={18} color="black60" />}
  </Icon>
);

const DropdownIcon = ({ icon }: { icon: React.ReactNode }) => (
  <Icon className="select-dropdown-item icon">{icon}</Icon>
);

const DropdownRadio = () => <div className="select-dropdown-item radio">Need implement</div>;

const DropdownCheckbox = () => <div className="select-dropdown-item checkbox">Need implement</div>;

const DropdownLetter = ({ letter }: { letter: string }) => (
  <ResponsiveTypography.Text
    className="select-dropdown-item letter"
    variant="medium/14-20"
    color="black100"
  >
    {letter}.
  </ResponsiveTypography.Text>
);

SelectDropdownItemContent.FillWidth = FillWidth;
SelectDropdownItemContent.Text = DropdownText;
SelectDropdownItemContent.SubText = DropdownSubText;
SelectDropdownItemContent.Check = DropdownCheck;
SelectDropdownItemContent.Icon = DropdownIcon;
SelectDropdownItemContent.Radio = DropdownRadio;
SelectDropdownItemContent.Checkbox = DropdownCheckbox;
SelectDropdownItemContent.Letter = DropdownLetter;
