import { FreeTestPromotionBanner } from '../../../../figma/Layout/Landing/ui/FreeTestPromotionBanner/FreeTestPromotionBanner';
import { FreeTestPromotionBannerTemplate } from '../../../../figma/Layout/Landing/ui/FreeTestPromotionBanner/FreeTestPromotionBannerTemplate';
import { IconSystemV2OtherPhoneCallFilled } from '@doltech/icons/SystemV2/IconSystemV2OtherPhoneCallFilled';
import { IconGoogleMaps1 } from '@doltech/icons/IconGoogleMaps1';
import { s3ToCDN } from '@doltech/utils/lib/url';
import * as React from 'react';
import { environment } from '@doltech/core/lib/environment/environment';
import { IconContact1 } from '@doltech/icons/SystemV2/IconContact1';
import { FreeTestPromotionInput } from '../../../../figma/Layout/Landing/ui/FreeTestPromotionBanner/FreeTestPromotionInput';
import { submitUserRegistration } from '../../../api/user-registration/user-registration-api';
import { showToast } from '../../../../figma//Toast';

export const LandingPromotionFreeTestBannerForm = () => {
  const [openFormContact, setOpenFormContact] = React.useState(false);

  const handleSave = async (userInfo) => {
    try {
      await submitUserRegistration(userInfo);
      showToast({
        icon: 'success',
        title: 'DOL đã nhận thông tin và sẽ liên hệ với bạn sớm nhất',
      });
      setOpenFormContact(false);
    } catch {
      showToast({
        icon: 'error',
        title: 'Có lỗi xảy ra',
      });
    }
  };

  if (openFormContact) {
    return (
      <FreeTestPromotionBannerTemplate
        imageUrl={s3ToCDN(
          'https://gqefcpylonobj.vcdn.cloud/directus-upload/037557e4-77c9-4d7a-aec8-2ce9a7e933f3.png'
        )}
        renderContent={
          <FreeTestPromotionInput
            title="Thông tin liên hệ của bạn"
            description="Hãy điền những thông tin dưới đây để DOL có thể tư vấn theo đúng nhu cầu của bạn nhé!"
            onBack={() => setOpenFormContact(false)}
            onSave={(userInfo) => handleSave(userInfo)}
          />
        }
      />
    );
  }
  return (
    <FreeTestPromotionBannerTemplate
      imageUrl={s3ToCDN(
        'https://gqefcpylonobj.vcdn.cloud/directus-upload/037557e4-77c9-4d7a-aec8-2ce9a7e933f3.png'
      )}
      renderContent={
        <FreeTestPromotionBanner
          title="Đăng ký test đầu vào IELTS miễn phí và nhận tư vấn"
          highlightText="miễn phí"
          infos={[
            {
              icon: <IconContact1 size={20} color="#2196F3" />,
              text: 'Để lại thông tin liên hệ',
              subText: 'Chúng tôi sẽ liên hệ với bạn ngay',
              onClick: () => setOpenFormContact(true),
            },
            {
              icon: <IconSystemV2OtherPhoneCallFilled size={20} color="#00A66C" />,
              text: 'Gọi điện liên hệ',
              subText: 'Liên hệ DOL qua hotline miễn phí: 1800 96 96 39',
              link: 'tel:1800 96 96 39',
            },
            {
              icon: <IconGoogleMaps1 size={20} />,
              text: 'DOL có 15+ cơ sở tại TP.HCM, Hà Nội và Đà Nẵng',
              subText: 'Click để xem địa chỉ chi tiết',
              link: `${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/he-thong-co-so`,
            },
          ]}
        />
      }
    />
  );
};
