import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';

const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 8px;
`;

interface InputGroupMaterialStyleLayoutProps {
  className?: string;
  labelContent?: React.ReactNode;
  inputContent?: React.ReactNode;
  captionContent?: React.ReactNode;
  error?: boolean;
  showCaption?: boolean;
}
export const InputGroupMaterialStyleLayout = (props: InputGroupMaterialStyleLayoutProps) => {
  const {
    className,
    labelContent,
    inputContent,
    captionContent,
    error = false,
    showCaption = false,
  } = props;

  return (
    <Container className={cl('input-group-grey-style-layout', className)}>
      {labelContent}
      {inputContent}
      {(showCaption || error) && captionContent}
    </Container>
  );
};
