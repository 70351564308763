import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';

import { IconCourseArrowArrow2DropDown } from '@doltech/icons/IconCourseArrowArrow2DropDown';
import { colorsV2 } from '../../colors-v2';
import { ResponsiveTypography } from '../../Typography/v3/ResponsiveTypography';

const Container = styled.div`
  display: flex;
  align-items: center;

  user-select: none;

  .text {
    flex: 1;
    color: ${colorsV2.black60};
  }

  &.selected {
    .text {
      color: ${colorsV2.black100};
    }
  }
`;

interface InputContentDropdownSelectProps {
  text?: string;
  placeholder?: string;
}

export const InputContentDropdownSelect = (props: InputContentDropdownSelectProps) => {
  const { text, placeholder } = props;

  return (
    <Container className={cl('input-content-dropdown-select', { selected: Boolean(text) })}>
      <ResponsiveTypography.Text variant="medium/14-20" className="text">
        {text || placeholder}
      </ResponsiveTypography.Text>
      <IconCourseArrowArrow2DropDown size={20} ml="8px" color="black60" />
    </Container>
  );
};
