import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';

import { sizeStyles } from './size-styles';
import { colorStyles } from './color-styles';
import { fontWeightStyles } from './font-weight-styles';
import { colorsV2 } from '../colors-v2';

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div``;

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  column-gap: 4px;

  border-radius: 8px;
  cursor: pointer;
  user-select: none;

  ${colorStyles}
  ${sizeStyles}
  ${fontWeightStyles}

  &.center {
    text-align: center;
  }

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }

  &.underline {
    text-decoration-line: underline;
  }

  &.disabled {
    cursor: not-allowed;
    color: ${colorsV2.neutral80};
    .icon {
      color: ${colorsV2.neutral60};
    }
  }
`;

interface TextLinkProps {
  disabled?: boolean;
  text?: string;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  align?: 'center' | 'left' | 'right';
  size?: 'small' | 'medium' | 'large' | 'extra-large';
  fontSize?: 'regular' | 'medium' | 'semi-bold';
  color?: 'default' | 'black' | 'gray' | 'neutral' | 'neutral-secondary' | 'blue' | 'red' | 'green' | 'primary' | 'purple';
  onClick?: (e?: any) => void;
  className?: string;
  underline?: boolean;
}

export const TextLink = (props: TextLinkProps) => {
  const {
    disabled,
    text,
    iconLeft,
    iconRight,
    size = 'small',
    fontSize = 'regular',
    align = 'left',
    color = 'black',
    onClick,
    className,
    underline = false,
    ...rest
  } = props;

  return (
    <Container
      className={cl(className, 'text-link', size, `font-weight-${fontSize}`, align, color, {
        underline,
        disabled,
      })}
      onClick={(e) => !disabled && onClick?.(e)}
      {...rest}
    >
      {iconLeft && <Icon className="icon icon-left">{iconLeft}</Icon>}
      <Text className="text">{text}</Text>
      {iconRight && <Icon className="icon icon-right">{iconRight}</Icon>}
    </Container>
  );
};
