import * as React from 'react';
import styled from 'styled-components';
import {
  compose,
  space,
  color,
  layout,
  typography,
  SpaceProps,
  ColorProps,
  LayoutProps,
  TypographyProps,
} from 'styled-system';

export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement> & {
    title?: any;
  }
>(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_851_165055)">
        <path
          d="M19.9991 4.24587V11.7096C19.9991 11.851 19.943 11.9865 19.843 12.0865C19.7431 12.1864 19.6075 12.2426 19.4662 12.2426H5.07203C4.7777 12.2426 4.53906 12.004 4.53906 11.7096V1.04713C4.53906 0.752802 4.77766 0.51416 5.07203 0.51416H19.4662C19.7605 0.51416 19.9992 0.752762 19.9992 1.04713V4.24587H19.9991Z"
          fill="#FF5364"
        />
        <path
          d="M19.8657 0.698016L12.7831 6.74611C12.4861 6.99691 12.0515 6.99691 11.7544 6.74611L4.67188 0.698016C4.77199 0.581411 4.91798 0.514277 5.07167 0.51416H19.4659C19.6196 0.514316 19.7655 0.58145 19.8657 0.698016Z"
          fill="#FB7B76"
        />
        <path
          d="M19.8093 12.1144C19.7135 12.1973 19.591 12.2427 19.4643 12.2424H5.07153C4.94492 12.2426 4.82246 12.1972 4.72656 12.1144L10.6318 5.78906L11.7543 6.74597C12.0513 6.99677 12.4859 6.99677 12.783 6.74597L13.9027 5.78906L19.8093 12.1144Z"
          fill="#DF4D60"
        />
        <path
          d="M4.36468 6.01243L5.35469 7.49746C5.55195 7.79331 5.48739 8.19123 5.2067 8.40951L3.98763 9.35779C3.7232 9.56165 3.64727 9.92803 3.80893 10.2202C4.16664 10.8625 4.50504 11.6214 6.18464 13.3013C7.86423 14.9812 8.62381 15.3189 9.26611 15.6766C9.55821 15.8386 9.92482 15.7627 10.1285 15.4979L11.0764 14.2792C11.2948 13.9985 11.6929 13.9339 11.9888 14.1312L13.5207 15.1526L15.2455 16.3017C15.5475 16.5021 15.6386 16.9045 15.4525 17.2154L14.6356 18.5766C14.419 18.9448 14.0689 19.2153 13.6581 19.3321C12.3006 19.7046 9.30683 19.7805 4.50782 14.9808C-0.291192 10.1812 -0.218705 7.18805 0.153853 5.83064C0.270536 5.41983 0.540946 5.06974 0.908971 4.85306L2.26946 4.0362C2.58048 3.85028 2.98269 3.94136 3.18323 4.24319L4.36468 6.01243Z"
          fill="#4FBA6F"
        />
      </g>
      <defs>
        <clipPath id="clip0_851_165055">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
});
export const IconContact1 = styled(SvgComponent)<IconProps>(
  {
    flex: 'none',
    verticalAlign: 'middle',
  },
  compose(space, color, layout, typography)
);
