import { css } from 'styled-components';

export const sizeStyles = css`
  &.small {
    .text {
      font-size: 12px;
      line-height: 20px;
    }

    .icon {
      font-size: 14px;
    }
  }

  &.medium {
    .text {
      font-size: 14px;
      line-height: 20px;
    }

    .icon {
      font-size: 16px;
    }
  }

  &.large {
    .text {
      font-size: 14px;
      line-height: 20px;
    }

    .icon {
      font-size: 16px;
    }
  }

  &.extra-large {
    .text {
      font-size: 16px;
      line-height: 24px;
    }

    .icon {
      font-size: 18px;
    }
  }
`;
