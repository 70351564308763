import * as React from 'react';
import styled from 'styled-components';
import {
  compose,
  space,
  color,
  layout,
  typography,
  SpaceProps,
  ColorProps,
  LayoutProps,
  TypographyProps,
} from 'styled-system';
import { nanoid } from 'nanoid';

export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement> & {
    title?: any;
  }
>(({ title, ...props }, svgRef) => {
  const [titleId] = React.useState(() => (title ? nanoid() : undefined));
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1057_67347)">
        <path
          d="M10.6445 10.4885L8.49774 13.8071L6.44918 14.6838C5.24219 13.301 3.95316 11.6604 3.34375 9.58615L4.57031 7.3049L6.54297 6.38693C6.50785 6.59787 6.48438 6.80881 6.48438 7.03146C6.48438 8.96502 8.06637 10.5471 10 10.5471C10.2227 10.5471 10.4336 10.5236 10.6445 10.4885Z"
          fill="#FFDA2D"
        />
        <path
          d="M13.4574 7.673C13.4926 7.46323 13.5156 7.25347 13.5156 7.03198C13.5156 5.09995 11.9359 3.5187 10.0043 3.51636H10C9.77734 3.51636 9.56641 3.5398 9.35547 3.57495L12.4727 0.457764C14.3594 1.16089 15.8711 2.67261 16.5742 4.55933L13.457 7.67651C13.457 7.67534 13.4574 7.67417 13.4574 7.673Z"
          fill="#80AEF8"
        />
        <path
          d="M16.573 4.5593L15.5066 6.64133L13.4559 7.67648C13.4559 7.67531 13.4563 7.67414 13.4563 7.67297C13.4914 7.4632 13.5145 7.25344 13.5145 7.03195C13.5145 5.09992 11.9348 3.51867 10.0031 3.51633H10V2.92922L10.702 1.36789L12.4715 0.457733C14.3582 1.16086 15.8699 2.67258 16.573 4.5593Z"
          fill="#4086F4"
        />
        <path
          d="M16.5743 4.5593L13.4571 7.67644C13.1875 9.09445 12.0626 10.2194 10.6446 10.489H10.6445L7.25109 13.8824L6.44922 14.6842C6.64848 14.9069 6.82422 15.1062 7.01176 15.3171C8.30086 16.782 9.41414 18.1257 9.41414 19.4148C9.41414 19.7429 9.67191 20.0007 10.0001 20.0007C10.3282 20.0007 10.586 19.7429 10.586 19.4148C10.586 18.1257 11.6993 16.782 12.9884 15.3171C14.7931 13.2897 17.0313 10.7585 17.0313 7.03195C17.0313 6.16476 16.879 5.33273 16.5743 4.5593Z"
          fill="#59C36A"
        />
        <path
          d="M16.575 4.5593L13.4578 7.67641V7.67644C13.1882 9.09445 12.0633 10.2194 10.6453 10.489C10.6452 10.489 10.6452 10.489 10.6452 10.489L10.002 11.1322V20.0005C10.3295 19.9998 10.5867 19.7424 10.5867 19.4148C10.5867 18.1257 11.7 16.782 12.989 15.3171C14.7937 13.2897 17.032 10.7585 17.032 7.03195C17.032 6.16476 16.8796 5.33273 16.575 4.5593Z"
          fill="#00A66C"
        />
        <path
          d="M10 11.1318L10.6433 10.4885C10.4328 10.5235 10.2223 10.5468 10 10.5469V11.1318Z"
          fill="#FDBF00"
        />
        <path
          d="M12.4726 0.457031L9.35546 3.57422C8.64062 3.71484 8.00777 4.05465 7.51562 4.54691L5.70312 3.78906L5.03125 2.0625C6.29691 0.785117 8.05472 0 9.99999 0C10.8672 0 11.6992 0.152344 12.4726 0.457031Z"
          fill="#4086F4"
        />
        <path
          d="M10 2.9276L12.4714 0.456151C11.6984 0.151581 10.8668 -0.000606689 10 -0.000762939V2.9276H10Z"
          fill="#4175DF"
        />
        <path
          d="M7.51562 4.54611C7.02348 5.0383 6.68356 5.67107 6.54297 6.38596L3.34375 9.58517C3.10937 8.80006 2.96875 7.95627 2.96875 7.03049C2.96875 5.08514 3.75387 3.32732 5.03125 2.06174L7.51562 4.54611Z"
          fill="#F03800"
        />
      </g>
      <defs>
        <clipPath id="clip0_1057_67347">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
});
export const IconGoogleMaps1 = styled(SvgComponent)<IconProps>(
  {
    flex: 'none',
    verticalAlign: 'middle',
  },
  compose(space, color, layout, typography)
);
