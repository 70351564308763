import { apis } from '@doltech/core/lib/api/api.config';
import { apiPublicUrls } from '@doltech/core/lib/api/api.public-urls';

export const submitUserRegistration = async (payload) => {
  const { data } = await apis.publicPageManagement.post(
    apiPublicUrls.pageManagement.SUBMIT_NEW_USER_REGISTRATION,
    payload,
    {}
  );
  return data;
};
