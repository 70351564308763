import { SpokePageSpinner } from '@doltech/core/lib/auth/SpokePageSpinner';
import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';
import { DynamicPageProps } from '@doltech/routeDefinition';
import cl from 'classnames';
import dynamic from 'next/dynamic';
import * as React from 'react';
import styled from 'styled-components';

import { LANDING_HEADER_HEIGHT } from '../constants';
import { LandingLayout, LandingLayoutContent } from '../LandingLayout';
import { LandingSideBar } from '../LandingSidebar/LandingSidebar';
import { LandingHeaderFullWidth } from '../PremadeHeader/LandingHeaderFullWidth';
import { LandingPromotionFreeTestBannerForm } from '../../../../shared/templates/LandingBlog/PromotionTypeForLandingPage/LandingPromotionFreeTestBannerForm';

const SharedFooter = dynamic<any>(() =>
  import('../Footer/SharedFooter').then((mod) => mod.SharedFooter)
);

const Main = styled.div`
  .landing-content {
    flex: 1;
  }

  .section-middle-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .section-limit-container {
    margin: 0 auto;
    padding: 0 16px;
  }

  &.no-header {
    .landing-section-middle {
      padding-top: 0;
    }
  }

  ${fromScreen(768)} {
    .section-limit-container {
      padding: 0 24px;
    }
  }

  ${fromScreen(1194)} {
    .landing-left-sidebar {
      position: sticky;
      top: ${LANDING_HEADER_HEIGHT}px;
      .user-info {
        display: none;
      }
    }

    .section-limit-container {
      padding: 0;
      max-width: 1080px;
    }
  }
`;
export interface LandingLayoutDefaultProps extends DynamicPageProps {
  children?: React.ReactNode;
  defaultSidebarOpen?: boolean;
  withHeader?: boolean;
  showPromotion?: boolean;
  withFooter?: boolean;
  logoColored?: boolean;
  loading?: boolean;
}

export const LandingLayoutDefaultContent = ({
  children,
  defaultSidebarOpen = false,
  withHeader = true,
  showPromotion = false,
  withFooter = true,
  logoColored = true,
  loading = false,
  ...rest
}: LandingLayoutDefaultProps) => {
  return (
    <Main className={cl({ 'no-header': !withHeader, 'no-footer': !withFooter })}>
      <LandingLayout defaultSidebarOpen={defaultSidebarOpen} {...rest}>
        {withHeader && (
          <LandingHeaderFullWidth logoColored={logoColored} showBanner={showPromotion} />
        )}
        {loading && <SpokePageSpinner />}
        {!loading && (
          <LandingLayoutContent>
            {withHeader && (
              <LandingLayoutContent.LeftOverlaySidebar displayAsSideBar>
                <LandingSideBar disableHeadTag />
              </LandingLayoutContent.LeftOverlaySidebar>
            )}

            <LandingLayoutContent.SectionMiddle>{children}</LandingLayoutContent.SectionMiddle>
          </LandingLayoutContent>
        )}
      </LandingLayout>
      <div className="empty-block" />
      <LandingPromotionFreeTestBannerForm />
      {withFooter && <SharedFooter />}
    </Main>
  );
};

const SectionLimitContainer = ({
  children,
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cl('section-middle-content section-limit-container', className)} {...rest}>
    {children}
  </div>
);

export const LandingLayoutDefault: any = LandingLayoutDefaultContent;

LandingLayoutDefault.LimitContainer = SectionLimitContainer;
