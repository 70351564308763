import * as React from 'react';
import styled from 'styled-components';
import {
  compose,
  space,
  color,
  layout,
  typography,
  SpaceProps,
  ColorProps,
  LayoutProps,
  TypographyProps,
} from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement> & {
    title?: any;
  }
>(({ title, ...props }, svgRef) => {
  const [titleId] = React.useState(() => (title ? nanoid() : undefined));
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.772 16.221c3.93 3.932 8.908 6.438 11.87 3.489l.718-.718a2.163 2.163 0 00-.288-3.312c-.7-.488-1.453-1.012-2.284-1.597a2.184 2.184 0 00-2.787.23l-.812.807c-1.006-.637-2.001-1.456-2.927-2.38l-.003-.004c-.924-.923-1.743-1.92-2.38-2.926l.807-.812a2.184 2.184 0 00.23-2.787A352.62 352.62 0 018.32 3.927a2.165 2.165 0 00-3.312-.288l-.718.718c-2.946 2.961-.443 7.937 3.487 11.87"
        clipRule="evenodd"
      />
    </svg>
  );
});
export const IconSystemV2OtherPhoneCallFilled = styled(SvgComponent)<IconProps>(
  {
    flex: 'none',
    verticalAlign: 'middle',
  },
  compose(space, color, layout, typography)
);
