import * as React from 'react';

function SvgIconSuccess(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="none" {...props}>
      <path opacity={0.01} fill="#fff" d="M0 0h24v24H0z" />
      <circle cx={12.84} cy={11.75} r={8.75} fill="#E3FBDA" />
      <path
        d="M16.313 9.571l-6.223 6.2-2.532-2.531M16.996 12.43a7.086 7.086 0 11-2.856-4.24"
        stroke="#1F853C"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const IconSuccess = React.memo(SvgIconSuccess);
