import { css } from 'styled-components';
import { colorsV2 } from '../colors-v2';

export const colorStyles = css`
  background-color: transparent;
  color: ${colorsV2.black100};
  .icon {
    color: ${colorsV2.black60};
  }

  &.black {
    color: ${colorsV2.black100};
    .icon {
      color: ${colorsV2.black100};
    }
  }

  &.primary {
    color: ${colorsV2.primary100};
    .icon {
      color: ${colorsV2.primary100};
    }
  }

  &.gray {
    color: ${colorsV2.black60};
    .icon {
      color: ${colorsV2.black60};
    }
  }

  &.neutral {
    color: ${colorsV2.neutral180};
    .icon {
      color: ${colorsV2.neutral140};
    }
  }

  &.neutral-secondary {
    color: ${colorsV2.neutral120};
    .icon {
      color: ${colorsV2.neutral100};
    }
  }

  &.blue {
    color: ${colorsV2.blue100};
    .icon {
      color: ${colorsV2.blue100};
    }
  }

  &.green {
    color: ${colorsV2.green100};
    .icon {
      color: ${colorsV2.green100};
    }
  }

  &.red {
    color: ${colorsV2.red100};
    .icon {
      color: ${colorsV2.red100};
    }
  }

  &.purple {
    color: ${colorsV2.purple100};
    .icon {
      color: ${colorsV2.purple100};
    }
  }

  &:hover:not(.disabled) {
    color: ${colorsV2.primary100};
    .icon {
      color: ${colorsV2.primary100};
    }

    &.black {
      color: ${colorsV2.primary100};
      .icon {
        color: ${colorsV2.primary100};
      }
    }

    &.primary {
      color: ${colorsV2.primary80};
      .icon {
        color: ${colorsV2.primary80};
      }
    }

    &.gray {
      color: ${colorsV2.black100};
      .icon {
        color: ${colorsV2.black80};
      }
    }

    &.neutral {
      color: ${colorsV2.primary100};
      .icon {
        color: ${colorsV2.primary100};
      }
    }

    &.neutral-secondary {
      color: ${colorsV2.neutral140};
      .icon {
        color: ${colorsV2.neutral140};
      }
    }

    &.blue {
      color: ${colorsV2.blue80};
      .icon {
        color: ${colorsV2.blue80};
      }
    }

    &.green {
      color: ${colorsV2.green80};
      .icon {
        color: ${colorsV2.green80};
      }
    }

    &.red {
      color: ${colorsV2.red80};
      .icon {
        color: ${colorsV2.red80};
      }
    }

    &.purple {
      color: ${colorsV2.purple80};
      .icon {
        color: ${colorsV2.purple100};
      }
    }
  }

  &:focus:not(.disabled),
  &:active:not(.disabled) {
    background-color: ${colorsV2.primary10};
    color: ${colorsV2.primary100};
    .icon {
      color: ${colorsV2.primary100};
    }

    &.black {
      background-color: ${colorsV2.primary10};
      color: ${colorsV2.primary100};
      .icon {
        color: ${colorsV2.primary100};
      }
    }

    &.primary {
      background-color: ${colorsV2.primary10};
      color: ${colorsV2.primary100};
      .icon {
        color: ${colorsV2.primary100};
      }
    }

    &.gray {
      background-color: ${colorsV2.gray10};
      color: ${colorsV2.black100};
      .icon {
        color: ${colorsV2.black80};
      }
    }

    &.neutral {
      background-color: ${colorsV2.primary10};
      color: ${colorsV2.primary100};
      .icon {
        color: ${colorsV2.primary100};
      }
    }

    &.neutral-secondary {
      background-color: ${colorsV2.neutral10};
      color: ${colorsV2.neutral180};
      .icon {
        color: ${colorsV2.neutral140};
      }
    }

    &.blue {
      background-color: ${colorsV2.blue10};
      color: ${colorsV2.blue80};
      .icon {
        color: ${colorsV2.blue80};
      }
    }

    &.green {
      background-color: ${colorsV2.green10};
      color: ${colorsV2.green80};
      .icon {
        color: ${colorsV2.green80};
      }
    }

    &.red {
      background-color: ${colorsV2.red10};
      color: ${colorsV2.red80};
      .icon {
        color: ${colorsV2.red80};
      }
    }

    &.purple {
      background-color: ${colorsV2.purple10};
      color: ${colorsV2.purple100};
      .icon {
        color: ${colorsV2.purple100};
      }
    }
  }
`;
