import { IconDanger } from '@doltech/icons-from-dev/colored/IconDanger';
import { IconInfo } from '@doltech/icons-from-dev/colored/IconInfo';
import { IconSuccess } from '@doltech/icons-from-dev/colored/IconSuccess';
import { IconWarning } from '@doltech/icons-from-dev/colored/IconWarning';
import { message } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

import { figmaColors } from '../colors';
import { colorsV2 } from '../colors-v2';
import renderNode, { Div } from '../renderNode';

const IconWrapper = styled.div`
  margin-right: 12px;
  font-size: 24px;
`;

const Content = styled.div`
  text-align: left;
  display: grid;
  gap: 4px;
  .title {
    color: ${figmaColors.notificationTitle};
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  .sub-title {
    color: ${figmaColors.notificationSubTitle};
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
  }
`;

const CustomContent = styled.div`
  text-align: left;
  .title {
    color: ${colorsV2.white100};
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  a {
    color: ${colorsV2.blue100};
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: 12px;
    text-decoration: underline;
  }
`;

interface ShowToastParams {
  icon: 'warning' | 'info' | 'success' | 'error' | 'none' | 'custom' | 'danger' | React.ReactNode;
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  duration?: number;
}

export const showToastBase = ({ icon, title, subTitle, duration = 2 }: ShowToastParams) => {
  let IconComponent = icon;
  switch (icon) {
    case 'info': {
      IconComponent = <IconInfo />;
      break;
    }
    case 'warning': {
      IconComponent = <IconWarning />;
      break;
    }
    case 'error':
    case 'danger': {
      IconComponent = <IconDanger />;
      break;
    }
    case 'success': {
      IconComponent = <IconSuccess />;
      break;
    }
    case 'custom': {
      IconComponent = null;
      return message.open({
        duration,
        type: 'custom' as any,
        icon: null,
        content: (
          <CustomContent>
            <div className="title">{title}</div>
          </CustomContent>
        ),
        className: 'assignment-custom-message',
      });
    }
    case 'supper-custom': {
      IconComponent = null;
      return message.open({
        duration,
        type: 'custom' as any,
        icon: null,
        content: <div className="title">{title}</div>,
        className: 'unstyled',
      });
    }
  }

  message.open({
    duration,
    type: 'info',
    icon: <IconWrapper>{renderNode(Div, IconComponent, {})}</IconWrapper>,
    content: (
      <Content>
        <div className="title">
          <span>{title}</span>
        </div>
        <div className="sub-title">
          <span>{subTitle}</span>
        </div>
      </Content>
    ),
  });
};

export const showToast = ({ icon, title, subTitle, duration = 2 }: ShowToastParams) => {
  message.config({
    maxCount: 1,
  });
  showToastBase({ icon, title, subTitle, duration });
};
