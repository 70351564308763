import { css, keyframes } from 'styled-components';

export const slideBottomTop = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translateY(-100%);
  }
`;

export const slideLeftRight = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translateX(100%);
  }
`;

export const slideRightLeft = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

export const slidingBottomToTop = css`
  animation: ${slideBottomTop} 24s linear both infinite;
`;

export const slidingLeftToRight = css`
  animation: ${slideLeftRight} 48s linear both infinite;
`;

export const slidingRightToLeft = css`
  animation: ${slideRightLeft} 48s linear both infinite;
`;
