import * as React from 'react';

function SvgIconDanger(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="none" {...props}>
      <path opacity={0.01} fill="#fff" d="M0 0h24v24H0z" />
      <path
        d="M21.71 7.92a1 1 0 01.286.709l-.055 6.394a1 1 0 01-.3.705l-4.56 4.482a1 1 0 01-.71.286l-6.394-.055a1 1 0 01-.705-.3l-4.482-4.56a1 1 0 01-.286-.71l.055-6.394a1 1 0 01.3-.705l4.56-4.482a1 1 0 01.71-.286l6.394.055a1 1 0 01.705.3l4.482 4.56z"
        fill="#FCE6DA"
      />
      <path
        clipRule="evenodd"
        d="M6.678 20.012l-3.19-3.19A1.667 1.667 0 013 15.643v-5.286c0-.442.176-.866.488-1.179l3.19-3.19A1.667 1.667 0 017.857 5.5h5.285c.442 0 .866.176 1.179.488l3.19 3.19c.313.313.489.737.489 1.179v5.286c0 .441-.176.865-.488 1.178L13.833 20.5H7.857c-.442 0-.866-.176-1.179-.488v0z"
        stroke="#B71928"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 15.5l5-5M13 15.5l-5-5"
        stroke="#B71928"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const IconDanger = React.memo(SvgIconDanger);
