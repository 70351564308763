import { css } from 'styled-components';

export const fontWeightStyles = css`
  &.font-weight-regular {
    .text {
      font-weight: 400;
    }
  }
  &.font-weight-medium {
    .text {
      font-weight: 500;
    }
  }
  &.font-weight-semi-bold {
    .text {
      font-weight: 600;
    }
  }
`;
