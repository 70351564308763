import cl from 'classnames';
import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from '../../colors-v2';

const Container = styled.div`
  padding: 8px 0;
  border-bottom: 2px solid ${colorsV2.gray20};
  input {
    color: ${colorsV2.gray120};
  }

  &::selection {
    border-bottom: 2px solid ${colorsV2.blue100};
  }

  &.with-action {
    cursor: pointer;
  }

  &:hover {
    border-bottom: 2px solid ${colorsV2.gray40};
  }

  &:focus,
  &:focus-within,
  &:active,
  &.active {
    border-bottom: 2px solid ${colorsV2.gray40};
  }

  &.error {
    border-bottom: 2px solid ${colorsV2.red100};
  }

  &.disabled {
    border-bottom: 0;
    cursor: not-allowed;

    input {
      color: ${colorsV2.black30};
    }
  }
`;

interface InputMaterialStyleLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  inputContent?: React.ReactNode;
  active?: boolean;
  error?: boolean;
  disabled?: boolean;
}

export const InputMaterialStyleLayout = React.forwardRef<HTMLDivElement, InputMaterialStyleLayoutProps>(
  (props: InputMaterialStyleLayoutProps, ref) => {
    const {
      className,
      inputContent,
      active,
      error,
      disabled,
      onClick,
      ...rest
    } = props;

    return (
      <Container
        ref={ref}
        className={cl('input-material-style-layout', className, {
          active,
          error,
          disabled,
          'with-action': Boolean(onClick),
        })}
        onClick={onClick}
        {...rest}
      >
        {inputContent}
      </Container>
    );
  }
);
