import * as React from 'react';
import { InputContentGeneral } from '../../../../../Input/InputContentGeneral/InputContentGeneral';
import { InputMaterialStyleLayout } from '../../../../../Input/InputMaterialStyleLayout/InputMaterialStyleLayout';
import { InputGroupMaterialStyleLayout } from '../../../../../Input/InputGroupMaterialStyleLayout/InputGroupMaterialStyleLayout';
import { ResponsiveTypography } from '../../../../../Typography/v3/ResponsiveTypography';
import { Asterisk } from '../../../../../Form/Asterisk/Asterisk';

export interface FreeTestInputNameProps {
  onChange?: (userName) => void;
  disabled?: boolean;
}

export const FreeTestInputName = (props: FreeTestInputNameProps) => {
  const { onChange, disabled } = props;

  return (
    <InputGroupMaterialStyleLayout
      labelContent={
        <ResponsiveTypography.Paragraph variant="semi-bold/16-24" color="gray180">
          Họ và tên:
          <Asterisk />
        </ResponsiveTypography.Paragraph>
      }
      inputContent={
        <InputMaterialStyleLayout
          inputContent={
            <InputContentGeneral
              placeholder="Nhập họ và tên"
              onChange={(userName) => {
                onChange?.(userName);
              }}
              disabled={disabled}
            />
          }
          disabled={disabled}
        />
      }
    />
  );
};
