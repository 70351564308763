import * as React from 'react';
import styled from 'styled-components';

import { colorsV2 } from '../../colors-v2';
import { shadows } from '../../shadows';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 8px 0px;
  background-color: ${colorsV2.white100};
  border: 1px solid ${colorsV2.gray20};
  box-shadow: ${shadows.zIndex5};
  border-radius: 8px;
`;

interface SelectOptionListProps {
  children?: React.ReactNode;
}

export const SelectOptionList = (props: SelectOptionListProps) => {
  const { children } = props;

  return <Container className="select-option-list">{children}</Container>;
};
