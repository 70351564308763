import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from '../../colors-v2';

const Styled = styled.span`
  color: ${colorsV2.red100};
`;

export const Asterisk = () => {
  return <Styled>*</Styled>;
};
