import * as React from 'react';

function SvgIconWarning(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="none" {...props}>
      <path opacity={0.01} fill="#fff" d="M0 0h24v24H0z" />
      <path
        d="M11.398 4c.77-1.333 2.694-1.333 3.464 0l7.795 13.5c.77 1.333-.193 3-1.733 3H5.336c-1.54 0-2.502-1.667-1.732-3L11.398 4z"
        fill="#FFF6AA"
      />
      <path
        d="M9.333 14.933v-3.116M9.332 17.438a.208.208 0 10.002.417.208.208 0 00-.002-.418"
        stroke="#283A5B"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M11.024 7.45l6.383 11.17c.741 1.297-.196 2.913-1.691 2.913H2.95c-1.496 0-2.434-1.616-1.691-2.914L7.642 7.45c.748-1.309 2.635-1.309 3.382 0z"
        stroke="#283A5B"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const IconWarning = React.memo(SvgIconWarning);
