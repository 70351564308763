import * as React from 'react';
import { InputContentGeneral } from '../../../../../Input/InputContentGeneral/InputContentGeneral';
import { InputMaterialStyleLayout } from '../../../../../Input/InputMaterialStyleLayout/InputMaterialStyleLayout';
import { InputGroupMaterialStyleLayout } from '../../../../../Input/InputGroupMaterialStyleLayout/InputGroupMaterialStyleLayout';
import { ResponsiveTypography } from '../../../../../Typography/v3/ResponsiveTypography';
import { Asterisk } from '../../../../../Form/Asterisk/Asterisk';

export interface FreeTestInputPhoneNumberProps {
  defaultValue?: string;
  onChange?: (phoneNumber) => void;
  disabled?: boolean;
}

export const FreeTestInputPhoneNumber = (props: FreeTestInputPhoneNumberProps) => {
  const { onChange, disabled } = props;
  return (
    <InputGroupMaterialStyleLayout
      labelContent={
        <ResponsiveTypography.Paragraph variant="semi-bold/16-24" color="gray180">
          Số điện thoại:
          <Asterisk />
        </ResponsiveTypography.Paragraph>
      }
      inputContent={
        <InputMaterialStyleLayout
          inputContent={
            <InputContentGeneral
              placeholder="Nhập số điện thoại"
              onInput={(input: any) => {
                input.target.value = input.target.value?.replace(/[^0-9]/g, '');
              }}
              onChange={(phoneNumber) => {
                onChange?.(phoneNumber);
              }}
              disabled={disabled}
            />
          }
          disabled={disabled}
        />
      }
    />
  );
};
