import * as React from 'react';
import styled from 'styled-components';
import { ResponsiveTypography } from '../../../../Typography/v3/ResponsiveTypography';
import { LAYER } from '@doltech/utils/lib/constants';
import { verticalSpace } from '@doltech/utils/lib/css-style';
import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';
import { IconSystemV2CourseArrowArrowLeftLine } from '@doltech/icons/SystemV2/IconSystemV2CourseArrowArrowLeftLine';
import { ButtonContentGeneral } from '../../../..//Button/ButtonContentGeneral/ButtonContentGeneral';
import { ButtonStyles } from '../../../../Button/ButtonStyles/ButtonStyles';
import { TextLink } from '../../../..//TextLink/TextLink';
import { FreeTestInputExpectedScore } from './InputForm/FreeTestInputExpectedScore';
import { FreeTestInputLocation } from './InputForm/FreeTestInputLocation';
import { FreeTestInputName } from './InputForm/FreeTestInputName';
import { FreeTestInputPhoneNumber } from './InputForm/FreeTestInputPhoneNumber';
import { ButtonSize } from '../../../../Button/ButtonSize/ButtonSize';

const Content = styled.div`
  ${verticalSpace(28)};

  ${fromScreen(768)} {
    width: 500px;
    ${verticalSpace(32)};
  }
`;

const Block = styled.div`
  display: grid;
  row-gap: 24px;

  .input-group-left {
    ${verticalSpace(24)};
  }

  .input-group-right {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
    row-gap: 0;
  }

  ${fromScreen(768)} {
    display: grid;
    grid-auto-flow: column;
    column-gap: 32px;

    .input-group-left,
    .input-group-right {
      display: block;
      ${verticalSpace(32)};
    }
  }
`;

const Text = styled.div`
  ${verticalSpace(8)};
`;

const Container = styled.div`
  position: relative;
  z-index: ${LAYER.LAYER1};
  ${verticalSpace(20)};

  ${fromScreen(768)} {
    ${verticalSpace(28)};
  }
`;

const Action = styled.div`
  width: max-content;
`;

const CENTER_OPTIONS = [
  { value: 'Quận 10, HCM', key: 'Q10' },
  { value: 'Bình thạnh, HCM', key: 'BT' },
  { value: 'Quận 3, HCM', key: 'Q3' },
  { value: 'Quận 4, HCM', key: 'Q4' },
  { value: 'Quận 6, HCM', key: 'Q6' },
  { value: 'Thủ Đức, HCM', key: 'TD' },
  { value: 'Phú Nhuận, HCM', key: 'PN' },
  { value: 'Gò Vấp, HCM', key: 'GV' },
  { value: 'Thanh Xuân, Hà Nội', key: 'TX.HN' },
  { value: 'Đống Đa, Hà Nội', key: 'DD.HN' },
  { value: 'Hai Bà Trưng, Hà Nội', key: 'HBT.HN' },
  { value: 'Hải Châu,Đà Nẵng', key: 'DN' },
];

const SCORE_OPTIONS = [
  { value: '4.5+', key: '4.5+' },
  { value: '5.0+', key: '5.0+' },
  { value: '5.5+', key: '5.5+' },
  { value: '6.0+', key: '6.0+' },
  { value: '6.5+', key: '6.5+' },
  { value: '7.0+', key: '7.0+' },
  { value: '7.5+', key: '7.5+' },
  { value: 'SAT 1300+', key: 'SAT1300+' },
  { value: 'SAT 1500+', key: 'SAT1500+' },
  { value: 'TOEIC 550+', key: 'TOEIC550+' },
  { value: 'TOEIC 750+', key: 'TOEIC759+' },
];

interface FreeTestPromotionInputProps {
  title?: string;
  description?: string;
  onBack?: () => void;
  onSave?: (userInfo) => void;
}

export const FreeTestPromotionInput = (props: FreeTestPromotionInputProps) => {
  const { title, description, onBack, onSave } = props;

  const [userInfo, setUserInfo] = React.useState({
    userName: '',
    center: '',
    targetScore: '',
    phoneNumber: '',
  });

  return (
    <Container>
      <Action>
        <TextLink
          color="gray"
          onClick={onBack}
          text="Quay về"
          size="medium"
          iconLeft={<IconSystemV2CourseArrowArrowLeftLine />}
        />
      </Action>
      <Content>
        <Text>
          <ResponsiveTypography.Paragraph
            className="title"
            breakpoints={[0, 768]}
            variant={['bold/24-32', 'bold/32-40']}
            color="gray180"
          >
            {title}
          </ResponsiveTypography.Paragraph>
          <ResponsiveTypography.Paragraph
            className="title"
            breakpoints={[0, 768]}
            variant={['regular/14-20', 'regular/16-24']}
            color="gray180"
          >
            {description}
          </ResponsiveTypography.Paragraph>
        </Text>
        <Block>
          <div className="input-group-left">
            <FreeTestInputName
              onChange={(userName) => setUserInfo((prev) => ({ ...prev, userName }))}
            />
            <FreeTestInputPhoneNumber
              onChange={(phoneNumber) => setUserInfo((prev) => ({ ...prev, phoneNumber }))}
            />
          </div>
          <div className="input-group-right">
            <FreeTestInputLocation
              value={userInfo.center}
              onChange={(center) => setUserInfo((prev) => ({ ...prev, center }))}
              options={CENTER_OPTIONS}
            />
            <FreeTestInputExpectedScore
              value={userInfo.targetScore}
              onChange={(targetScore) => {
                setUserInfo((prev) => ({ ...prev, targetScore }));
              }}
              options={SCORE_OPTIONS}
            />
          </div>
        </Block>
        <Action>
          <ButtonStyles
            color="primary"
            buttonStyle="filled"
            colorStyle="bold"
            disabled={Object.values(userInfo).some((value) => value === '')}
            onClick={() => onSave(userInfo)}
            content={
              <ButtonSize verticalPadding content={<ButtonContentGeneral text="Gửi thông tin" />} />
            }
          />
        </Action>
      </Content>
    </Container>
  );
};
