import * as React from 'react';
import styled from 'styled-components';
import { DolPopover } from '../../Popover/DolPopover';
import { DolTooltipProps } from '../../Tooltip/DolTooltip';
import { InputMaterialStyleLayout } from '../../Input/InputMaterialStyleLayout/InputMaterialStyleLayout';

const DropdownContent = styled.div`
  position: relative;
`;

const Container = styled.div``;

export interface SelectMaterialStyleInputGeneralProps extends Pick<DolTooltipProps, 'placement'> {
  inputContent?: React.ReactNode;
  dropdownContent?: React.ReactNode;
  disabled?: boolean;
}

export const SelectMaterialStyleInputGeneral = (props: SelectMaterialStyleInputGeneralProps) => {
  const { placement = 'bottom-start', inputContent, dropdownContent, disabled = false } = props;
  const [visible, setVisible] = React.useState(false);
  const inputLayoutRef = React.useRef(null);

  return (
    <Container className="select-material-style-input-general">
      <DolPopover
        placement={placement}
        trigger="click"
        overlayClassName="un-styled no-arrow custom-inner-content"
        visible={!disabled && visible}
        onVisibleChange={setVisible}
        content={({ setTooltipOpen }) => (
          <DropdownContent
            style={{ width: inputLayoutRef?.current?.offsetWidth }}
            onClick={() => setTooltipOpen(false)}
          >
            {dropdownContent}
          </DropdownContent>
        )}
        disabled={disabled}
      >
        <InputMaterialStyleLayout
          ref={inputLayoutRef}
          inputContent={inputContent}
          disabled={disabled}
          active={visible}
        />
      </DolPopover>
    </Container>
  );
};
