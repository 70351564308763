import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from '../../colors-v2';

const Container = styled.div`
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 8px;
  }

  input {
    text-overflow: ellipsis;
    height: auto;
    flex: 1;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${colorsV2.black100};

    &::placeholder {
      color: ${colorsV2.black60};
    }

    &:disabled {
      color: ${colorsV2.black80};
    }
  }
`;

export interface InputContentGeneralProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  addOn?: boolean;
  disabled?: boolean;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;

  value?: string | number;
  placeholder?: string;
  onChange?: (changeValue: any) => void;

  inputType?: 'text' | 'number';
}

export const InputContentGeneral = React.forwardRef<HTMLInputElement, InputContentGeneralProps>(
  (props, ref) => {
    const { addOn, iconLeft, iconRight, value, placeholder, onChange, disabled, inputType = 'text', ...rest } = props;

    if (addOn) {
      return (
        <Container>
          {iconLeft}
          <input
            ref={ref}
            type={inputType}
            value={value}
            placeholder={placeholder}
            onChange={(e) => onChange?.(e.target.value)}
            {...rest}
          />
          {iconRight}
        </Container>
      );
    }

    return (
      <Container>
        <input
          ref={ref}
          type={inputType}
          value={value}
          placeholder={placeholder}
          onChange={(e) => onChange?.(e.target.value)}
          disabled={disabled}
          {...rest}
        />
      </Container>
    );
  }
);
