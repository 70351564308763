import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from '../../../../colors-v2';
import { ResponsiveTypography } from '../../../../Typography/v3/ResponsiveTypography';
import { LAYER } from '@doltech/utils/lib/constants';
import { horizontalSpace, verticalSpace } from '@doltech/utils/lib/css-style';
import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';

const Container = styled.div`
  position: relative;
  z-index: ${LAYER.LAYER1};

  width: 343px;
  display: flex;
  flex-direction: column;
  align-items: start;
  ${verticalSpace(24)};

  ${fromScreen(768)} {
    width: 500px;
  }

  ${fromScreen(1128)} {
    ${verticalSpace(32)};
  }
`;

const Block = styled.div`
  ${verticalSpace(24)};
`;

const Item = styled.div`
  display: flex;
  ${horizontalSpace(12)};

  .icon-wrapper {
    width: 40px;
    aspect-ratio: 1;
    background-color: ${colorsV2.gray10};
    border-radius: 12px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  cursor: pointer;

  ${fromScreen(768)} {
    ${horizontalSpace(16)};
  }
`;

interface FreeTestPromotionBannerProps {
  title?: string;
  highlightText?: string;
  infos?: {
    icon?: React.ReactNode;
    text?: string;
    subText?: string;
    link?: string;
    onClick?: () => void;
  }[];
}

export const FreeTestPromotionBanner = (props: FreeTestPromotionBannerProps) => {
  const { title, highlightText, infos } = props;
  const index = title.indexOf(highlightText);
  const part1 = title.substring(0, index).trim();
  const part2 = title.substring(index + highlightText.length).trim();

  return (
    <Container>
      <ResponsiveTypography.Paragraph
        className="title"
        breakpoints={[0, 768]}
        variant={['bold/24-32', 'bold/32-40']}
        color="gray180"
      >
        {part1}
        <ResponsiveTypography.Text color="primary100"> {highlightText} </ResponsiveTypography.Text>
        {part2}
      </ResponsiveTypography.Paragraph>
      <Block>
        {infos?.map((info, i) => {
          const { icon, text, subText, link, onClick } = info || {};
          return (
            <Item
              key={i}
              onClick={() => {
                if (link) {
                  window.open(link, '_blank');
                }
                onClick?.();
              }}
            >
              <div className="icon-wrapper">{icon}</div>
              <div className="text-wrapper">
                <ResponsiveTypography.Paragraph
                  color="gray180"
                  breakpoints={[0, 768]}
                  variant={['bold/16-24', 'bold/18-24']}
                >
                  {text}
                </ResponsiveTypography.Paragraph>
                <ResponsiveTypography.Paragraph variant="medium/12-20" color="gray160">
                  {subText}
                </ResponsiveTypography.Paragraph>
              </div>
            </Item>
          );
        })}
      </Block>
    </Container>
  );
};
