import * as React from 'react';
import styled from 'styled-components';

import { SpokeLoading } from './SpokeLoading';

const Main = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 60vw;
  min-height: 50vh;
  display: grid;
  place-items: center;
  .overlay-spin {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .text {
    font-size: 20px;
    font-weight: 600;
    color: #d14242;
  }
`;

export const SpokePageSpinner = ({ text = '' }) => {
  return (
    <Main className="spoke-page-spinner">
      <div className="overlay-spin">
        <SpokeLoading width={32} height={32} color="#D14242" />
        <div className="text">{text}</div>
      </div>
    </Main>
  );
};
