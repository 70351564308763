import { InputContentDropdownSelect } from '../../../../../Input/InputContentDropdownSelect/InputContentDropdownSelect';
import { SelectOptionList } from '../../../../../Select/SelectOptionList/SelectOptionList';
import { DropdownItemItemLayout } from '../../../../../Select/DropdownItemItemLayout/DropdownItemItemLayout';
import { SelectDropdownItemContent } from '../../../../../Select/SelectDropdownItemContent/SelectDropdownItemContent';
import { InputGroupMaterialStyleLayout } from '../../../../../Input/InputGroupMaterialStyleLayout/InputGroupMaterialStyleLayout';
import * as React from 'react';
import { SelectMaterialStyleInputGeneral } from '../../../../../Select/SelectMaterialStyleInputGeneral/SelectMaterialStyleInputGeneral';
import { ResponsiveTypography } from '../../../../../Typography/v3/ResponsiveTypography';
import styled from 'styled-components';
import { Asterisk } from '../../../../../Form/Asterisk/Asterisk';

interface FreeTestInputLocationProps {
  value?: string;
  onChange?: (changeValue?: string) => void;
  options?: {
    key?: string;
    value?: string;
  }[];
  disabled?: boolean;
}

const Container = styled.div`
  .select-option-list {
    max-height: 180px;
    overflow-y: auto;
  }
`;

export const FreeTestInputLocation = (props: FreeTestInputLocationProps) => {
  const { value: selectedKey, onChange, options, disabled = false } = props;

  const selectedOption = React.useMemo(() => {
    return options?.find((option) => option.key === selectedKey);
  }, [options, selectedKey]);
  return (
    <InputGroupMaterialStyleLayout
      labelContent={
        <ResponsiveTypography.Paragraph variant="semi-bold/16-24" color="gray180">
          Địa điểm học:
          <Asterisk />
        </ResponsiveTypography.Paragraph>
      }
      inputContent={
        <SelectMaterialStyleInputGeneral
          inputContent={
            <InputContentDropdownSelect
              text={selectedOption?.value}
              placeholder="Chọn tỉnh thành"
            />
          }
          dropdownContent={
            <Container>
              <SelectOptionList>
                {options?.map(({ key, value }) => (
                  <DropdownItemItemLayout
                    key={key}
                    content={
                      <SelectDropdownItemContent>
                        <SelectDropdownItemContent.Text text={value} />
                      </SelectDropdownItemContent>
                    }
                    onClick={() => onChange?.(key)}
                    selected={key === selectedKey}
                  />
                ))}
              </SelectOptionList>
            </Container>
          }
          disabled={disabled || !options?.length}
        />
      }
    />
  );
};
