import * as React from 'react';

function SvgIconInfo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="none" {...props}>
      <path fill="#fff" d="M0 0h24v24H0z" />
      <rect x={4.503} y={3} width={16.5} height={16.5} rx={3} fill="#DAF4FE" />
      <path
        clipRule="evenodd"
        d="M13.838 20.503h-6.67A4.169 4.169 0 013 16.335v-6.67a4.169 4.169 0 014.168-4.168h6.67a4.169 4.169 0 014.168 4.168v6.67a4.169 4.169 0 01-4.168 4.168z"
        stroke="#235DAF"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.502 9.665a.209.209 0 10.002.417.209.209 0 00-.002-.417M10.712 16.488v-3.96h-.834"
        stroke="#235DAF"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const IconInfo = React.memo(SvgIconInfo);
